<template>
    <div class="editUser">
        <div class="editContent">
            <p>基础信息</p>
            <div class="basicInfo">

                <el-form :model="basicRuleForm" :rules="basicRules" ref="basicRuleForm" label-width="100px"
                    class="demo-ruleForm">
                    <el-form-item label="姓名">
                        <el-input v-model="basicRuleForm.nickname" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="登录名" prop="username">
                        <el-input v-model="basicRuleForm.username" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="basicRuleForm.mobile" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select size="small" v-model="basicRuleForm.status" placeholder="请选择" style="width: 100%">
                            <el-option label="正常" :value="1"></el-option>
                            <el-option label="禁用" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <p>新建用户默认密码为：<span>123456</span></p>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitBasicForm('basicRuleForm')" size="small">提交</el-button>
                        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
           
        </div>
    </div>
</template>

<script>
import { addUserAPI, userDetailAPI, updateUserAPI } from '@/api/user/user'
export default {
    data() {
        
        return {
            isEdit: false,
            // 密码数据
            
            // 基础信息
            basicRuleForm: {
                nickname: null,
                username: null,
                mobile: null,
                status: 1,
            },
            basicRules: {
                username: [
                    { required: true, message: '这是必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            }

        };
    },
    methods: {
        // 基础信息提交  新建/修改
        submitBasicForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    let res = {};
                    if (this.isEdit === true) {
                        res = await updateUserAPI(JSON.stringify(this.basicRuleForm));
                        console.log(res);
                    } else {
                        res = await addUserAPI(JSON.stringify(this.basicRuleForm));
                        console.log(res);
                    }

                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        
        // 获取用户详情
        async getUserDetail() {
            const res = await userDetailAPI({ id: this.$route.query.id });
            console.log(res);
            if (res.code === 200) {
                this.basicRuleForm = res.data;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        }
    },
    created() {
        if (this.$route.query.id) {
            this.isEdit = true
            this.getUserDetail();
        }
    }

}
</script>

<style lang="scss" scoped>
.editUser {
    width: 100%;

    .editContent {
        border-radius: 5px;
        width: 60%;
        margin: 0 auto;
        // border: 1px solid #eee;
        box-shadow: 0px 0px 4px 1px #ccc;
        padding: 20px;

        p {
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
        }

        .basicInfo,
        .updatePassword {
            margin: auto;
            width: 60%;
            // border-bottom: 1px solid #ccc;
        }
    }
}
</style>